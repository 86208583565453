body {
  margin: 0;
  font-family: Georgia;

}
.btn{
  border-radius: 4px;
  border: unset;
  padding: 15px 25px;
  display: inline-block;
  -moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    text-transform: uppercase;
    background: linear-gradient(to right, #fc4b69, #fd6d62 50%, #0eb85d00 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    background-color:#fe3e5e;

}
.btnsubmit{
  border-radius: 4px;
  border: unset;
    width: 100%;
    max-width: 150px;
    background-color: #fe3e5e!important;
    text-align: center;
    padding: 10px 0px;
    color: white;

    display: inline-block;
    -moz-transition: all .5s ease-in-out;
      -o-transition: all .5s ease-in-out;
      -webkit-transition: all .5s ease-in-out;
      text-transform: uppercase;
      background: linear-gradient(to right, #fc4b69, #fd6d62 50%, #0eb85d00 50%);
      background-size: 200% 100%;
      background-position: right bottom;
      transition: all .5s ease-out;
      background-color:#fe3e5e;
    
}
.btnsubmit:hover{
  background-color: #ff5447;
  background-position: 100% 0;
  background-position: left bottom;
}

.btn:hover{

  background-color: #ff5447;
  background-position: 100% 0;
  background-position: left bottom;

}
.section {
  width: 100%;
}
.logo img {
  width: 100%;
}
nav.navbar.navbar-expand-lg.bg-light {
  background: transparent!important;
}
.container-fluid {
  padding: 0;
}
section#Header {
  width: 100%;
  display: inline-block;
  padding: 15px 0;
}

 
/* CUSTOM CSS */
.navigation li.nav-item {
  margin-right: 25px;
} 
.navigation a.nav-link {
  font-family: Georgia;
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  color: #000000;
  letter-spacing: 0.28px;
  line-height: 47px;
}
.navigation a.nav-link:hover {
  font-family: Georgia;
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  color: #fe3e5e;
  letter-spacing: 0.28px;
}
.navigation a.nav-link.active{
  color: #fe3e5e;
}


.banner-wrapper {
  width: 100%;
  display: inline-block;
}
.banner-wrapper .banner-image {
  width: 100%;
  display: inline-block;
}
.banner-wrapper .banner-image img {
  width: 100%;
}
.main-banner {
  width: 100%;
  display: flex;
  align-items: center;
} 
.section.banner {
  background-position: 50%!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  padding: 110px 0;
  position: relative;
  z-index: 9;
  overflow: hidden;
}  
h4.sub-heading {
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.25px;
}
.main-banner .content-side {
  width: 100%;
  max-width: 50%;
  display: inline-block;
}
h1.heading{
  font-size: 84px;
    text-align: left;
    color: #ffffff;
    font-family: "Georgia - Bold";
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}
span.colored{
  color: #fe3e5e;
    background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
    -webkit-text-fill-color: transparent;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: animationgradienttitle;
    -webkit-background-clip: text;
    display: block;
    font-weight: 600;
    text-shadow: none;
}
p.description {
  color: #ffffff;
  font-size: 23px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.4px;
  line-height: normal;
  width: 100%;
  max-width: 75%;
  line-height:33.86px;
}
.main-banner .content-side .btn {
  margin-top: 25px;
}
.services-icons {
  width: 50%;
  display: inline-block;
}
.services-icons .icons_side {
  width: 530px;
  height: 530px;
  margin: 0 auto;
  display: block;
}
.services-icons .icon_container{
 width: 100%;
 display: inline-block; 
}
.services-icons .icon_container ul {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.services-icons .icon_container ul li {
  list-style: none;
  margin: 0;
  padding: 0;
} 
.services-icons .icon_container {
  border-radius: 100%;
  height: 100%;
  padding: 50px;
  position: relative;
  width: 100%;
}
.services-icons .logo-center {
  border-radius: 100%;
  height: 100%;
  padding: 170px;
  position: relative;
  width: 100%;
}
.services-icons .logo-center ul.main-logo {
  width: 110px!important;
  height: 110px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
  padding: 8px;
  border-radius: 100%;
}
.services-icons .logo-center ul.main-logo li.center-logo {
  background: #fff;
  border-radius: 100%;
  padding: 10px;
  width: 85px;
  height: 85px;
}
.services-icons .logo-center ul.main-logo img {
  width: 100%;
  animation-name: spin;
  animation-duration: 20000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.services-icons ul.icons {
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.services-icons .icon_container ul.icons li{
  align-items: center;
  background-color: #fff;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  height: 42px;
  justify-content: center;
  position: absolute;
  -webkit-transform: translatey(0);
  -moz-transform: translatey(0);
  -o-transform: translatey(0);
  transform: translatey(0);
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  width: 42px;
}
.services-icons .icon_container:before {
  content: '';
  background-image: url(https://cdn.shopify.com/s/files/1/0655/8207/2047/files/Compound_Path.png?v=1669722741);
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  top: 14px;
  width: 100%;
  height: 100%;
  left: 9px;
  right: 0;
  opacity: 1;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.services-icons li.circle-logo1 {
  left: 220px;
  right: 0px;
  top: -30px;
  width: 90px!important;
  height: 90px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo2 {
  left: 250px;
  right: 0px;
  bottom: -35px;
  width: 90px!important;
  height: 90px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo3 {
  right: -12px;
  top: 92px;
  bottom: 0px;
  width: 90px!important;
  height: 90px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo4 {
  right: -36px;
  top: 337px;
  bottom: 0px;
  width: 90px!important;
  height: 90px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo5 {
  left: 0px;
  top: 355px;
  bottom: 0;
  width: 90px!important;
  height: 90px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo6 {
  left: -4px;
  top: 110px;
  bottom: 0;
  width: 90px!important;
  height: 90px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo7 {
 right: 139px;
 top: 76px;
 width: 70px!important;
 height: 70px!important;
 border: 4px solid #2b5a74;
 background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo8 {
 right: 34px;
 top: 227px;
 width: 70px!important;
 height: 70px!important;
 border: 4px solid #2b5a74;
 background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo9 {
 right: 111px;
 top: 392px;
 width: 70px!important;
 height: 70px!important;
 border: 4px solid #2b5a74;
 background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo10 {
 left: 142px;
 top: 404px;
 width: 70px!important;
 height: 70px!important;
 border: 4px solid #2b5a74;
 background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo11 {
  left: 52px;
  top: 241px;
  width: 70px!important;
  height: 70px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}
.services-icons li.circle-logo12 {
  left: 140px;
  top: 89px;
  width: 70px!important;
  height: 70px!important;
  border: 4px solid #2b5a74;
  background-image: linear-gradient(to top, #002a41 0%, #004e79 100%);
}



.services-icons li.circle-logo1:before {
  content: '-----------------------------';
    position: absolute;
    transform: rotate(87deg);
    bottom: -116px;
    color: #8f8f8f;
    width: 200px;
    left: -45px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}
.services-icons li.circle-logo2:before {
  content: '-----------------------------';
    position: absolute;
    transform: rotate(86deg);
    top: -112px;
    color: #8f8f8f;
    width: 200px;
    left: -68px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}
.services-icons li.circle-logo3:before {
  content: '-----------------------------';
    position: absolute;
    transform: rotate(-32deg);
    top: 96px;
    color: #8f8f8f;
    width: 200px;
    left: -181px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}
.services-icons li.circle-logo4:before {
  content: '-----------------------------';
    position: absolute;
    transform: rotate(24deg);
    top: -32px;
    color: #8f8f8f;
    width: 200px;
    right: 68px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}

.services-icons li.circle-logo5:before {
  content: '-----------------------------';
    position: absolute;
    transform: rotate(-29deg);
    top: -45px;
    color: #8f8f8f;
    width: 200px;
    left: 64px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}
.services-icons li.circle-logo6:before {
  content: '-----------------------------';
    position: absolute;
    transform: rotate(26deg);
    top: 89px;
    color: #8f8f8f;
    width: 200px;
    left: 72px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}

.services-icons li.circle-logo7:before {
  content: '-----------------';
    position: absolute;
    transform: rotate(114deg);
    bottom: -94px;
    color: #8f8f8f;
    width: 180px;
    left: -108px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}
.services-icons li.circle-logo8:before {
  content: '-----------------';
  position: absolute;
  transform: rotate(177deg);
  bottom: 10px;
  color: #8f8f8f;
  width: 180px;
  left: -183px;
  opacity: 0.3;
  font-weight: 100;
  font-size: 18px;
}
.services-icons li.circle-logo9:before {
  content: '-----------------';
    position: absolute;
    transform: rotate(235deg);
    bottom: 118px;
    color: #8f8f8f;
    width: 180px;
    left: -133px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}
.services-icons li.circle-logo10:before {
  content: '-----------------';
    position: absolute;
    transform: rotate(121deg);
    bottom: 68px;
    color: #8f8f8f;
    width: 180px;
    left: -26px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}
.services-icons li.circle-logo11:before {
  content: '-----------------';
  position: absolute;
  transform: rotate(177deg);
  bottom: 20px;
  color: #8f8f8f;
  width: 180px;
  left: 0px;
  opacity: 0.3;
  font-weight: 100;
  font-size: 18px;
}
.services-icons li.circle-logo12:before {
  content: '-----------------';
    position: absolute;
    transform: rotate(236deg);
    bottom: -30px;
    color: #8f8f8f;
    width: 180px;
    left: -25px;
    opacity: 0.3;
    font-weight: 100;
    font-size: 18px;
}

/* .services-icons .icon_container ul.icons li img:hover{
  -webkit-animation-name: shake;
    -webkit-animation-duration: 0.8s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -webkit-transform-origin: 50% 100%;
} */

.services-icons .icon_container ul.icons .circle-logo1 img{
  animation: floating 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo2 img{
  animation: floating 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo3 img{
  animation: floating 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo4 img{
  animation: floating 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo5 img{
  animation: floating 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo6 img{
  animation: floating 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo7 img{
  animation: floating1 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo8 img{
  animation: floating1 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo9 img{
  animation: floating1 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo10 img{
  animation: floating1 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo11 img{
  animation: floating1 4s ease-in-out infinite;
}
.services-icons .icon_container ul.icons .circle-logo12 img{
  animation: floating1 4s ease-in-out infinite;
}



.background-spin-images img.small-shape {
  position: absolute;
  right: 43%;
  bottom: -30px;
  z-index: -1;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.background-spin-images img.small-shape2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.background-spin-images img.small-shape1 {
  position: absolute;
  top: 35%;
  right: 5%;
  z-index: -1;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 140px;
}
.background-spin-images img.small-shape3 {
  position: absolute;
  top: 0%;
  right: 48%;
  z-index: -1;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 180px;
}
.background-spin-images img.small-shape4 {
  position: absolute;
  top: -13%;
  left: -94px;
  z-index: -1;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: 280px;
}




/* START TRUSTED CLIENTS SECTION */

#Turstedclients {
  padding: 60px 0 20px 0;
}
#Turstedclients h2 {
  color: #010101;
  font-family: Georgia;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
}
.brandslogos-wrap {
  width: 100%;
  display: inline-block;
  align-items: center;
}
.brandslogos-wrap .img-wrap {
  display: inline-block;
  float: left;
  width: 277px;
  height: 108px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  text-align: center;
  position: relative;
  margin-right: 50px;
}
.brandslogos-wrap .img-wrap:hover {
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
}
.brandslogos-wrap .img-wrap:last-child {
  margin: 0!important;
}
.brandslogos-wrap .img-wrap img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#Turstedclients .slick-initialized .slick-slide{
  padding: 20px 10px;
}
/* END TRUSTED CLIENTS SECTION */





/* START OUR SERVICES SECTION */
#Ourservices {
  padding: 60px 0;
}



.hidebtn{
  display:none;
}
.our-services .section-title {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: 50px;
  font-weight: 700;
}
.our-services p.section-content {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  width: 45%;
  color: #000000;
  letter-spacing: 0.32px;
  margin: 0 auto;
  float: none;
  padding-top: 20px;

}
.ourservices-wrap {
  width: 100%;
  display: inline-block;
  padding-top: 50px;
}
.ourservices-wrap .service-wrap {
  width: 100%;
  max-width: 31%;
  float: left;
  text-align: center;
  display: inline-block;
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
  border-radius: 33px;
  background-color: #ffffff;
  margin-bottom: 55px;
  padding: 70px 50px;
  transition: all .5s ease-in-out;
}
.ourservices-wrap .service-wrap:nth-child(2) {
  margin: 0 3.5%;
}
.ourservices-wrap .service-wrap:nth-child(5) {
  margin: 0 3.5%;
}
.services-wrappers h4 {
  color: #010101;
  font-size: 29px;
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 10px;

}
.services-wrappers p {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: 25px;
}
.services-wrappers .btn {
  border-radius: 4px;
  border: 1px solid #fc4c69;
  background-color: #ffffff!important;
  background-image: unset;
  color: #fe3e5e;
  font-size: 17px;
  width: 197px;
  padding: 9px 25px;
  margin-top: 15px;
}
.service-wrap:hover {
  box-shadow: 0 6px 33px rgba(234, 48, 78, 0.22);
  background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
  transition: all .5s ease-in-out;
}

/* END OUR SERVICES SECTION */

/* START PROGRESS SECTION */
#Progress {
  background-color: #ff5447;
  background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
  padding: 60px 0;
  position: relative;
  overflow: hidden;
}
.progress-wrap {
  width: 100%;
  display: inline-block;
}
.progress-wrap .img-wrap {
  width: 100%;
  max-width: 23%;
  display: inline-block;
  text-align: center;
  margin-right: 2.66%;
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
  border-radius: 6px;
  background-color: #ffffff;
  padding: 9px;
}
.progress-wrap .img-wrap:last-child {
  margin-right: 0!important;
}
.progress-spin-images {
  position: absolute;
  width: 100%;
}
.progress-spin-images img.progress-small-shape1 {
  position: absolute;
  top: -138px;
  right: -73px;
  width: 220px;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.progress-spin-images img.progress-small-shape2 {
  position: absolute;
  left: -70px;
  top: 88px;
  animation-name: spin;
  animation-duration: 90000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.progress-wrap h2.progress-number {
  color: #fe3e5e;
  background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
  -webkit-text-fill-color: transparent;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: animationgradienttitle;
  -webkit-background-clip: text;
  display: block;
  font-weight: 600;
  text-shadow: none;
  font-family: "Arial - Bold MT";
  font-size: 74px;
  margin: 0;
}
.progress-wrap p.title {
  color: #000000;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}
.progress-wrap .content-wrapper {
  border-radius: 4px;
  border: 1px dashed #fe3e5e;
  background-color: #ffffff;
  width: 100%;
  display: inline-block;
}

/* END OUR SERVICES SECTION */


/* START WORK PROCESS SECTION */
#Workprocess {
  padding: 60px 0;
}
.work-process .section-title {
  color: #010101;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
.work-process .sub-title {
  color: #000000;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0.47px;
  text-align: center;
  padding-top: 5px;
}
.work-process  .work-process-wrap {
  width: 100%;
  display: inline-block;
  padding: 60px 150px 50px 150px;
}
.work-process .work-process-wrap .img-wrap {
  width: 100%;
  max-width: 20%;
  display: inline-block;
  text-align: center;
}

.work-process .work-process-wrap .img-wrap .content-wrapper {
  width: 170px;
  margin: 0 auto;
  box-shadow: 0 5px 19px rgb(244 63 85 / 22%);
  /* border: 1px dashed #fe3e5e; */
  background-color: #ffffff;
  height: 170px;
  border-radius: 100%;
  padding: 10px;
  position: relative;
  transform-origin: 200% center;
}

.work-process .work-process-wrap .img-wrap .content-wrapper .image img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  
}
.work-process .work-process-wrap .img-wrap .content-wrapper .image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
  z-index: 9999;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}


.work-process .work-process-wrap .img-wrap .content-wrapper:after {
  content: '';
  width: 170px;
  height: 170px;
  position: absolute;
  background: red;
  border-radius: 100%;
  top: 0px;
  z-index: 9999;
  animation: rotate 50s linear infinite;
  border: 1px dashed #fe3e5e;
  left: 0;
  right: auto;
  bottom: 0;
  background: transparent;
  z-index: 1;
}

.work-process .work-process-wrap .img-wrap .content-wrapper .image:hover {
  transform: rotateX(360deg);
}






.service-page.work-process .work-process-wrap .img-wrap:hover .image:hover {
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
/* .service-page.work-process .work-process-wrap .img-wrap:hover .image:hover {
  transform: rotateX(360deg);

} */
.work-process .work-process-wrap .img-wrap .content-wrapper .image {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  position: relative;
}
.work-process .work-process-wrap .img-wrap:hover .image{
  background-color: #ffffff;
background-image: linear-gradient(to top, #fc4b69 0%, #fd6d62 100%);
}
.work-process .work-process-wrap .img-wrap:hover .process-title{
  color: #fe3e5e;
}
.work-process .work-process-wrap .process-title {
  color: #010101;
  font-size: 23px;
  font-weight: 400;
  text-align: center;
  padding-top: 20px;
}
.work-process .content-foo p.bottom-cntent {
  width: 100%;
  max-width: 60%;
  margin: 0 auto;
  float: none;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.32px;
  margin-bottom: 30px;
}
.work-process .content-foo a.btn {
  color: #ffffff;
}
.content-foo {
  text-align: center;
  width: 100%;
  display: inline-block;
}
/* END WORK PROCESS SECTION */


/* START BEST PORTFOLIO SECTION */

#Bestportfolio {
  background-position: 50%!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  padding: 110px 0;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.portfolio-wrap .img-wrap{
    display: flex!important;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}


.portfolio-wrap .content-wrapper {
  width: 50%;
  display: inline-block;
  float: left;
}
.portfolio-wrap .image-wrapper {
  width: 100%;
  max-width: 50%;
  display: inline-block;
  float: left;
}

.portfolio-wrap p.tagline {
  color: #fe3e5e;
  font-size: 26px;
  font-weight: 700;
  width: auto;
  text-align: left;
  margin: 0;
}
.portfolio-wrap p.technology-name {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.27px;
}
.portfolio-wrap .title {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
  text-align: left;
}
.portfolio-wrap p.content {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  line-height: 31.53px;
  text-align: left;
  letter-spacing: 0.32px;
  width: 70%;
}
.portfolio-wrap .image-wrapper img{
  float: right;
  margin-right: 50px;
  width: 100%;
  border-radius: 18px;
}
.portfolio-wrap button.slick-arrow{
  display: none!important;
}
.portfolio-wrap  ul.slick-dots {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  bottom: 0;
  margin: auto 0;
  height: 100px;
}
.portfolio-wrap .slick-dots li {
  display: block!important;
  margin-bottom: 8px;
}
.portfolio-wrap  .slick-dots li button:before {
  font-size: 15px;
  line-height: 21px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #ffffff;
  width: 20px;
  height: 20px;
}
.portfolio-wrap  .slick-dots li.slick-active button:before {
  opacity: .75;
  color: #ffffff;
}
.portfolio-wrap .content-wrapper a.btn {
  color: #ffffff;
}


/* END  BEST PORTFOLIO SECTION */


/* START TRUST US SECTION */
#Trustus {
  padding: 80px 0 80px 0;
  background-color: #f7f7f7;
}
.trust-us h2.section-title {
  text-align: center;
  color: #010101;
  font-size: 50px;
  font-weight: 700;
}
.trust-us p.section-content {
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  line-height: 33.53px;
  letter-spacing: 0.32px;
  width: 50%;
  text-align: center;
  margin: 0 auto;
  padding-top: 15px;
}
.trust-us .trustus-wrap {
  width: 100%;
  display: inline-block;
  padding-top: 55px;
}
.trust-us .trustus-wrap .trust-wrap {
  width: 100%;
  max-width: 31%;
  float: left;
  text-align: center;
  display: inline-block;
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
  border-radius: 33px;
  background-color: #ffffff;
  padding: 70px 50px;
  min-height: 420px;
}
.trust-us .trustus-wrap .trust-wrap:nth-child(2) {
  margin: 0 3.5%;
}
.trust-us .trustus-wrap .trust-wrap h4 {
  color: #000000;
  font-size: 29px;
  font-weight: 700;
  text-align: center;
  padding-top: 30px;
}
.trust-us .trustus-wrap .trust-wrap p {
  color: #000000;
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.32px;
}
.trust-us .trust-wrap:hover {
background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
}

/* END TRUST US SECTION */


/* START CUSTOMER SAYS SECTION */
#Customersays {
  padding: 80px 0 80px 0;
}
.customer-says .banner-wrapper .content-section {
  width: 30%;
  display: inline-block;
  float: left;
}
.customer-says .banner-wrapper .customers-wrap {
  width: 70%;
  display: inline-block;
  float: left;
  text-align: center;
  box-shadow: 0 4px 15px rgb(254 62 94 / 12%);
  border-radius: 26px;
  border: 1px solid rgba(254, 62, 94, 0.22);
  background-color: #ffffff;
  padding: 40px 30px 40px 30px;
}
.customer-says .banner-wrapper .content-section .content-wrap {
  text-align: left;
    width: 100%;
    display: inline-block;
    max-width: 90%;

}
.customer-says .banner-wrapper .content-section .title {
  color: #010101;
  font-size: 50px;
  font-weight: 700;
  text-align: left;
  line-height: normal;
  padding: 15px 0 10px 0;
}

.customer-says .banner-wrapper .content-section .content {
  color: #000000;
  font-size: 19px;
  font-weight: 400;
  line-height: 33.53px;
  text-align: left;
  letter-spacing: 0.32px;
  width: 100%;
}

.customer-says .banner-wrapper .customers-wrap .customer-logo {
  width: 100%;
  text-align: center;
  display: inline-block;
}





.customer-says .banner-wrapper .customers-wrap .customer-logo img {
  margin: 0 auto;
  float: none;
}
.customer-says .banner-wrapper .customers-wrap .website-name {
  color: #232323;
  font-size: 29px;
  font-weight: 700;
  line-height: 50.77px;
  text-align: center;
}
.customer-says .banner-wrapper .customers-wrap .content {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.32px;
  line-height: inherit;
  display: block;
  max-width: 80%;
  margin: 0 auto;
  font-style: italic;

}
.customer-says .banner-wrapper .customers-wrap .customer-name {
  color: #fe3e5e;
  font-size: 19px;
  font-weight: 400;
  line-height: 28.89px;
  width: 100%;
  padding-top: 15px;
  margin: 0;
}
.customer-says .slick-next:before, .customer-says .slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: #fe3e5e;
  width: 40px;
  height: 40px;
  box-shadow: 0 6px 14px rgb(254 62 94 / 22%);
  border: 1px solid #fe3e5e;
  background-color: #ffdbe1;
  padding: 5px 3px 3px 4px;
  border-radius: 100%;
}
.customer-says .slick-prev {
  left: -45px;
}
.customer-says .slick-next {
  right: -35px;
}
.customer-says .slick-next:focus:before, .customer-says.slick-next:hover:before, .customer-says .slick-prev:focus:before, .customer-says .slick-prev:hover:before {
  opacity: 1;
  box-shadow: 0 6px 14px rgb(254 62 94 / 22%);
  background-color: #fe3e5e;
  color: #fff;
}
.customer-says .banner-wrapper .customers-wrap .review_section {
  display: flex!important;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.content-wrapper .review_section a{
  text-decoration: none;
  text-align: center;
 }
 
/* END CUSTOMER SAYS SECTION */



/* START PARTNERS LOGOS SECTION */
#Partnerslogos {
  background-color: #f7f7f7;
  padding: 20px 0;
}
#Partnerslogos button.slick-arrow{
display: none!important;
}
#Partnerslogos .brandslogos-wrap .img-wrap {
  border: unset;
  background-color: unset;
}
#Partnerslogos .brandslogos-wrap .img-wrap:hover {
  box-shadow: unset;
}

/* END PARTNERS LOGOS SECTION */



/* START FOOTER SECTION */
#Footer {
  background-position: 50%!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  padding: 110px 0 0 0;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.footer .footer-wrapper {
  width: 100%;
  display: flex;
}


.footer .footer-wrapper .footer-blocks:nth-child(1) {
  width: 35%;
}
.footer .footer-wrapper .footer-blocks:nth-child(2) {
  width: 15%;
}

.footer .footer-wrapper .footer-blocks:nth-child(3) {
  width: 22%;
}

.footer .footer-wrapper .footer-blocks:nth-child(4) {
  width: 28%;
}

.footer .footer-wrapper p,.footer .footer-wrapper label,.footer .footer-wrapper h2 {
  color: #ffffff;
}

.footer p.content {
  width: 391px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  padding-top: 20px;
}
.footer-blocks .social-sharing {
  width: 100%;
  display: inline-block;
}
.footer-blocks .social-sharing ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.footer-blocks .social-sharing ul li a.socail-link {
  width: 100%;
  display: inline-block;
  height: 100%;
}
.footer-blocks .social-sharing ul li a.socail-link img {
  padding-top: 8px;
}
.footer-blocks .social-sharing ul li a.socail-link:hover {
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 5px 72px rgb(0 0 0 / 3%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #ffffff;
}

.for-call {
  width: 100%;
  display: inline-block;
  margin-top: 20px;
}
.for-call a.socail-link {
  width: 100%;
  max-width: 220px;
  display: inline-block;
  box-shadow: 0 10px 14px rgb(254 62 94 / 12%);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.22);
  text-decoration: none;
  padding: 5px;
}
.for-call a.socail-link p.label {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
.for-call a.socail-link .contact-number {
  color: #fe3e5e!important;
  font-family: "Arial - Bold MT";
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  margin: auto;
}
.footer-blocks .social-sharing ul li {
  width: 36px;
  height: 36px;
  box-shadow: 0 5px 72px rgb(0 0 0 / 3%);
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #34586c;
  margin-right: 16px;
  text-align: center;
}

.footer-blocks h2.footer-title {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
}
.footer-blocks ul {
  width: 100%;
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}
.footer-blocks ul li {
  width: 100%;
  display: inline-block;
}

.footer-blocks ul li a.footer-nav-link {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: inherit;
  text-align: left;
  letter-spacing: 0.28px;
  text-decoration: none;
  line-height: 31.14px;
}
.footer-blocks .address {
  width: 100%;
  display: inline-block;
  margin-top: 15px;
}
.footer-blocks .address .line-items {
  display: flex;
  width: 100%;
}
.footer-blocks .address .line-items label {
  margin-right: 25px;
  width: 85px;
}
.footer-blocks .address .line-items label {
  margin-right: 25px;
  width: 85px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.28px;
}
.footer-blocks .address .line-items p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.28px;
}
.footer-blocks .address .line-items a.mail-link {
  text-decoration: none;
  color: #ffffff;
}
.contact-form form ul input, .contact-form form ul textarea {
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.22);
  background-color: transparent;
  margin-bottom: 10px;
  padding: 8px 10px;
}
.contact-form .submit-contact-from {
  color: #fff;
  padding: 15px 40px;
}
.footer .footer-copyright {
  padding: 40px;
  margin-top: 100px;
  border-top: 2px solid #063e5f;
  opacity: 0.7;
}
.footer .footer-copyright p.text-center {
  color: #ffffff;
}

/* END FOOTER SECTION */




/* START SERVICE PAGE SECTION */

.service-page {
  background-position: 50%!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  padding: 60px 0;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.service-page #counter-box {
  display: flex!important;
  justify-content: center;
  align-items: center;
  min-height: 640px;
  width: 100%;
}
.service-page #counter-box .content-wrapper {
  width: 55%;
  max-width: 100%;
  display: inline-block;
  float: left;
}
.service-page .image-wrapper {
  width: 45%;
  max-width: 100%;
  display: inline-block;
  float: left;
  position: relative;
}
.service-page .image-wrapper .img {
  width: 100%;
  display: inline-block;
  position: relative;
}
.service-page.image-right .image-wrapper .img .icon-wrapper {
  position: absolute;
  top: 50px;
  left: -50px;
  background: #ffffff;
  padding: 15px;
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
  border-radius: 19px;
  background-color: #ffffff;
}
.service-page.image-left .image-wrapper .img .icon-wrapper {
  position: absolute;
  top: 50px;
  right: -50px;
  background: #ffffff;
  padding: 15px;
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
  border-radius: 19px;
  background-color: #ffffff;
}

.service-page .image-wrapper .img img {
  width: 100%;
}
.service-page .content-wrapper .tagline {
  color: #fe3e5e;
  font-size: 26px;
  font-weight: 700;
  text-align: left;
}

.service-page .content-wrapper .tagline_new{

  color: #ffffff;
    font-size: 26px;
    font-weight: 700;
    text-align: left;
}
.service-page .content-wrapper .title {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
  text-align: left;
}
.service-page .content-wrapper .content {
  width: 85%;
  color: #ffffff;
  font-size: 21px;
  font-weight: 400;
  line-height: 33.63px;
  text-align: left;
  letter-spacing: 0.32px;
}
.service-page .content-wrapper .btn{
  color: #ffffff;
}
.service-page.middle-section .btn {
  background-color: #ffffff!important;
  background-image: unset;
  color: #fe3e5e;
}

.service-page.image-left .content-wrapper .title {
  color: #000;
}
.service-page.image-left .content-wrapper .content {
  color: #000;
  width: 100%;
  max-width: 100%;
}
.service-page.image-left .content-wrapper {
  padding-left: 130px;
}


.service-page.work-process .work-process-wrap .img-wrap {
  width: 100%;
  max-width: 16.66%;
  display: inline-block;
  text-align: center;
}
.service-page.work-process .work-process-wrap {
  width: 100%;
  display: inline-block;
  padding: 40px 0px 60px 0px;
}
.service-page.work-process .work-process-wrap .img-wrap:hover .image{
  background-color: unset;
    background-image: unset;
}
.service-page.work-process .work-process-wrap .img-wrap:hover .process-title {
  color: #010101;
}


/* END SERVICE PAGE SECTION */




/* START PORTFOLIO PAGE SECTION */

#Portfolio {
  padding: 60px 0;
}
.our-portfolio h2.section-title {
  color: #010101;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
/* .our-portfolio .portfolio-wrap {
  width: 100%;
  display: inline-block;
  padding-top: 50px;
} */

.our-portfolio .portfolio-wrap {
  width: 100%;
  display: flex;
  padding-top: 50px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.our-portfolio .portfolio-wrap .service-wrap {
  width: 100%;
  max-width: 33.33%;
  display: inline-block;
  float: left;
  text-align: center;
  padding: 30px;

}
.our-portfolio .portfolio-wrap .service-wrap .services-wrappers {
  width: 100%;
  display: inline-block;
  position: relative;
  cursor:pointer;

}
.our-portfolio .portfolio-wrap .service-wrap .services-wrappers img {
  width: 100%;
  border-radius: 26px;

}

.our-portfolio .portfolio-wrap .service-wrap .services-wrappers .cntent-wrapper {
  background-color: #ff5447;
  background-image: linear-gradient(90deg,#fc4b69 0,#fd6d62);
  border-radius: 25px;
  bottom: 0;
  display: inline-block;
  left: 0;
  opacity: .9;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  padding: 17px;
}


.our-portfolio .portfolio-wrap .service-wrap .services-wrappers:hover .cntent-wrapper{
  display: block!important;
}

.our-portfolio .portfolio-wrap .service-wrap:hover{
  border-radius: unset;
    background-color: unset;
    background-image: unset;
}
/* .our-portfolio .portfolio-wrap .service-wrap .services-wrappers .cntent-wrapper .wrapper{
  padding: 96px 15px;
  border: 1px solid #ffffff;
  margin: 15px;
  border-radius: 25px;
} */
.our-portfolio .portfolio-wrap .service-wrap .services-wrappers .cntent-wrapper .wrapper {
  padding: 96px 15px;
  border: 1px solid #ffffff;
  /* margin: 15px; */
  border-radius: 25px;
  border: 1px solid #fff;
  border-radius: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.our-portfolio .portfolio-wrap .service-wrap .services-wrappers .cntent-wrapper .wrapper h4 {
  color: #ffffff;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
}

.our-portfolio .portfolio-wrap .service-wrap .services-wrappers .cntent-wrapper .wrapper p{
color: #ffffff;
font-size: 23px;
font-weight: 400;
line-height: 35.1px;
text-align: center;
letter-spacing: 0.32px;
}

/* END PORTFOLIO PAGE SECTION */




/* START CONTACT PAGE SECTION */
#Contactform {
  background-position: 50%!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  padding: 60px 0;
  position: relative;
  z-index: 9;
  height: 600px;
}
.contact-form-wrapper {
  position: absolute;
  width: 1441px;
  height: 710px;
  box-shadow: 0 8px 28px rgb(0 46 70 / 8%);
  border-radius: 41px;
  background-color: #ffffff;
  padding: 35px;
  margin: 0 auto;
  float: none;
  top: 40%;
    left: 0;
    right: 0;
    float: none;
}
.contact-from h1.title {
  color: #ffffff;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
.contact-from p.content {
  width: 100%;
  max-width: 60%;
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.32px;
  margin: 0 auto;
  float: none;
}

.contact-from h2.form-heading {
  color: #000000;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
.contact-from h2.form-heading-col {
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  color: #fe3e5e;
}
.contact-from .form-wrapper {
  width: 100%;
  display: inline-block;
  padding-top: 15px;
}
.contact-from form#ContactForm .filed-wrap {
  width: 50%;
  display: inline-block;
  float: left;
  padding: 0 10px;
  margin-bottom: 20px;
}
.contact-from form#ContactForm {
  padding: 30px 50px 0 50px;
}

form#ContactForm .filed-wrap label {
  width: 100%;
  display: inline-block;
  color: #010101;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
.contact-from form#ContactForm .filed-wrap input {
  width: 100%;
  box-shadow: 0 13px 18px rgb(0 0 0 / 12%);
  border-radius: 8px;
  border: 1px solid #00000052;
  background-color: #ffffff;
  opacity: 0.8;
  padding: 13px 15px;
}
.contact-from .filed-wrap.full-width {
  width: 100%!important;
}
.contact-from .filed-wrap.full-width textarea {
  width: 100%;
  height: 140px;
  box-shadow: 0 13px 18px rgb(0 0 0 / 12%);
  border-radius: 8px;
  border: 1px solid #00000052;
  background-color: #ffffff;
  opacity: 0.8;
  padding: 10px 15px;
}
.contact-from form#ContactForm a.btn {
  color: #ffffff;
  margin-left: 10px;
}



section#Ouroffice {
  margin-top: 450px;
}
.address-wrap {
  width: 100%;
  display: flex;
  padding-top: 50px;
  align-items: center;
}
.address-wrap .map-block {
  width: 50%;
  display: inline-block;
}
.address-wrap .address-block {
  width: 50%;
  display: inline-block;
}

.address-wrap .address-block .address {
  width: 100%;
  height: 480px;
  border-radius: 28px;
  background-color: #ffffff;
  background-image: linear-gradient(to top, rgba(0, 42, 65, 0.98) 0%, rgba(0, 78, 121, 0.98) 98%, rgba(0, 78, 121, 0.98) 100%);
  padding: 35px 35px 35px 35px;
}
.address-wrap .map-block .map iframe {
  width: 100%;
  border-radius: 28px;
}
.address-wrap .map-block .map {
  width: 100%;
  padding-left: 20px;
  border-radius: 28px;
  background-color: #ffffff;
}

.office-wrapper .title {
  color: #010101;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
.address-wrap .address-block .address ul {
  list-style: none;
  margin: 0;
  padding: 15px 30px;
  width: 100%;
}
.address-wrap .address-block .address ul li {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 60px;
}
span.address-content {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.27px;
  text-decoration: none;
}
span.address-content a {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0.27px;
  text-decoration: none;
}
.address-wrap .address-block .address ul li span.icon {
  width: 40px;
  margin-right: 45px;
}
section#Lookingfor {
  background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
  margin: 70px 0 0 0;
}
.looking-for .looking-wrapper {
  padding: 120px 0;
  text-align: center;
  color: #ffff;
}
.looking-for .looking-wrapper h2.title {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
.looking-for .looking-wrapper p.content {
  width: 100%;
  max-width: 85%;
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.32px;
  margin: 0 auto;
  float: none;
}
.looking-for .looking-wrapper .lets-talk-btn {
  margin-top: 35px;
  background: #fff;
  color: #fe3e5e;
}

/* END CONTACT PAGE SECTION */


/* start of pop section */


.portfolio_popup {
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  z-index: 9999;
}

.portfolio_popup_img {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  cursor:pointer;
}
.portfolio_popup_img img {
  width: 100%;
}
.portfolio_popup_wrap {
  position: relative;
  height: auto;
  width: 100%;
}
.portfolio_popup_wrap .full_page_img {
  width: 100%;
  margin: 0px  auto;
  max-width: 60%;
  height: 100vh;
  overflow: auto;
  padding: 50px 0px;

}
.portfolio_popup_wrap .full_page_img img {
  width: 100%;

}
.portfolio_popup_wrap .full_page_img .multiple_shot_img {
  padding-top: 50px;
}
/* hide scrollbar */
::-webkit-scrollbar {
  display: none;
}

.popup{
  display: none;
	width: 100%;
	float: none;
	background-color: rgba(255, 255, 255, 0.9);
	height: 100vh;
	display: grid;
	place-items: center;
}
.popup .container{
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	float: none;
	display: block;
}

.popup .wraper{
	display: inline-block;
	width: 100%;
	background: #ffffff;
	border-radius: 34px;
	padding: 55px 55px;
	position: relative;
	z-index: 999999;
}
.popup .wraper-left{
	width: 40%;
	display: inline-block;
	float: left;
}
.popup .text{
	display: inline-block;
	width: 60%;
	float: right;
}
.popup .image{
	position: relative;
}
.popup .inputs{
	width: 100%;
	display: inline-block;
	float: left;
	margin-top: 15px;
}
.popup .details{
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	color: white;
	background-color: #fe3e5e;
	background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
	opacity: 0.95;
	padding: 25px 25px;
    border-radius: 0px 0px 20px 20px;
}
.popup input{
	padding: 10px 13px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	font-size: 17px;
	display: inline-block;
	float: left;
	border-radius: 6px;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
	width: 100%;
}
.popup #select{
	padding: 10px 13px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	font-size: 17px;
	display: inline-block;
	float: left;
	border-radius: 6px;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
	width: 100%;
}
.popup .number{
  padding: 10px 0px;
	display: flex;
	text-align: center;
	align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

}

.popup input.submit {
	border-radius: 4px;
	padding: 12px 13px;
	background-color: #fe3e5e;
	color: white;
	width: 100%;
	max-width: 160px;
  text-align:center;
}
.popup .Submit {
	width: 100%;
	display: inline-block;
	float: left;
	padding: 0;
}
.popup .heding2{
	padding: 0 30px;
	font-family: Georgia;
	font-weight: 500;
}
.popup .heading{
	width: 100%;
	color: #ffffff;
	font-family: Georgia;
	font-size: 23px;
	font-style: italic;
	letter-spacing: 0.37px;
	line-height: normal;
}
.popup .heading3{
	font-size: 26px;
	font-family: Georgia;
	width: 100%;
	margin-bottom: 25px;
}
.popup .name{
	width: 50%;
	display: inline-block;
	float: left;
	margin-bottom: 25px;
	padding: 0 15px 0 0;
}
.popup input.req {
	width: 100%;
	display: inline-block;
}
.popup span{
	color: #fe3e5e;
}
.popup .name.request textarea {
	padding: 10px 13px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	display: inline-block;
	float: left;
	border-radius: 6px;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06);
	width: 100%;
	min-height: 85px;
	font-family: Georgia;
	font-size: 17px;
	font-weight: 400;
}
.popup .name.request {
    width: 100%;
}
.popup .input-filed {
	width: 100%;
	display: inline-block;
	float: left;
	padding: 10px 30px;
}
.popup span.requirments{
	font-family: Georgia;
	font-size: 15px;
	font-weight: 400;

}

.popup input::-webkit-input-placeholder { /* Edge */
	color: #010101;
	font-family: Georgia;
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
	text-align: left;
	font-style: normal;
	letter-spacing: normal;
	line-height: normal;
}
.popup .wraper::after {
    content: '';
    position: absolute;
    top: -17px;
    width: 350px;
    left: -24px;
    /* background-image: url(left-cross.png); */
    z-index: -1;
    height: 350px;
}
.popup .wraper::before {
    content: '';
    position: absolute;
    top: -17px;
    right: 0px;
    width: 102px;
    height: 115px;
    /* background-image: url(right-cross.png); */
}
.popup .cross{
	position: relative;
    top: -27px;
    right: -27px;
    float: right;
    cursor: pointer;
}
.popup .heading2{
	font-weight: 400;
	padding: 0 20px;
}
a {
	display: flex;
    color: white;
    font-family: Georgia;
    font-weight: 400;
}
img.new-img {
  width: 100%;
}
.popup .image {
  padding-top: 36px;
}
.main {
  position: relative;
}
.popup {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
}
.popup .cross {
  width: 30px;
  height: 30px;
}


@media screen and (max-width: 1080px) {

.popup .wraper-left {
    display: unset!important;
    padding-top: 10px;
    padding-bottom: 10px;
    float:none!important;
    width: 40%;
}
.wraper {
  display: flex!important;
  align-items: center;
  justify-content: space-between;
  position:relative;
}
.popup .text{
  display: unset!important;
  float: none!important;
  width: 60%;
}
.popup .image {
  padding-top: 0px;
}
.popup .cross {
    position: absolute;
    top: 14px;
    right: 18px;
}




}

@media screen and (max-width: 1024px) {
         
     .popup .container {
    padding: 0 20px;
}




}






@media screen and (max-width: 390px) {
 .popup .wraper{
 	padding: 55px 20px;
 }
.popup .cross{
	top: -28px;
    right: 5px;
}
.popup .wraper-left{
	display: none;
}
.popup .image{
	display: none;
}
.popup .text{
	    width: 100%;
}
.popup .input-filed{
	    padding: 0;
}
.popup .inputs{
	    margin-top: 12px;

}
.popup .name{
	margin-bottom: 10px;
	width: 100%;
}
.popup .heading3{
	font-size: 20px;
}
.popup .wraper::after{
	display: none;
}
}





@media screen and (max-width: 768px) {
.popup .details{
	position: unset;
}
}


/* end of popup section */

/* START ABOUT PAGE SECTION */
section#AboutProgress {
  background-position: 50%!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  padding: 200px 0;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.about-progress-section h1.title {
  width: 100%;
  color: #ffffff;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
}
.about-progress-section p.content {
  width: 100%;
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.32px;
  margin-bottom: 35px;
  display: inline-block;
}
#Aboutservices {
  padding: 70px 0;
}

.about-our-services .section-title {
  color: #010101;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
.about-our-services .section-content {
  width: 50%;
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.32px;
  margin: 0 auto;
  float: none;
}
.about-our-services .about-ourservices-wrap {
  width: 100%;
  display: inline-block;
  padding-top: 50px;
}

.about-our-services .about-ourservices-wrap .about-service-wrap {
  width: 50%;
  display: inline-block;
  margin-bottom: 40px;
}
.about-our-services .about-services-wrappers {
  padding: 35px;
  width: 100%;
  display: flex;
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
  border-radius: 12px;
  background-color: #ffffff;
  max-width: 95%;
}
.about-our-services .about-services-wrappers .img-wrapper {
  width: 20%;
  display: inline-block;
}
.about-our-services .content-wrapper {
  width: 80%;
  display: inline-block;
}

.about-our-services .about-services-wrappers:hover {
  box-shadow: 0 8px 26px rgb(234 48 78 / 22%);
  border-radius: 12px;
  background-color: #ffffff;
  background-image: linear-gradient(to right, #fc4b69 0%, #fd6d62 100%);
}
.about-our-services .about-services-wrappers:hover .btn{
  background: #ffffff;
  color: #fe3e5e;
  
}
#Awesometeam {
  padding: 70px 0;
  background-position: 50%!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
  position: relative;
  z-index: 9;
  overflow: hidden;
}
.awesometeam-wrapper {
  width: 100%;
  display: inline-block;
}
.awesometeam-wrapper h2.title {
  color: #ffffff;
  font-size: 50px;
  font-weight: 700;
  text-align: center;
}
.awesometeam-wrapper .content {
  color: #ffffff;
  font-size: 32px;
  font-weight: 400;
  line-height: 33.53px;
  text-align: center;
  letter-spacing: 0.47px;
}
.awesometeam-wrap {
  width: 100%;
  display: inline-block;
  padding-top: 100px;
}


.awesometeam-wrap .team-member-wrap {
  width: 100%;
  max-width: 25%;
  display: inline-block;
  text-align: center;
  margin-bottom: 100px;
  padding: 10px;
}
.awesometeam-wrap .team-member-wrap .content-wrapper {
  width: 100%;
  box-shadow: 0 5px 14px rgb(0 0 0 / 12%);
  border-radius: 12px;
  background-color: #ffffff;
  padding: 100px 0 25px 0;
  position: relative;
}
.awesometeam-wrap .team-member-wrap .content-wrapper img {
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  margin: 0 auto;
  float: none;
  border-radius: 100%;
  box-shadow: 0 5px 19px rgb(244 63 85 / 22%);
    border: 2px solid#fff;
    background: #ffffff;
}

.awesometeam-wrap .team-member-wrap .content-wrapper .name {
  color: #010101;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.awesometeam-wrap .team-member-wrap .content-wrapper .designation {
  color: #fe3e5e;
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.33px;
}


/* END ABOUT PAGE SECTION */

.ourservices-wrap .service-wrap:hover h4 {
  color: #ffffff;
}

.ourservices-wrap .service-wrap:hover .services-wrappers p{
  color: #ffffff;
}

.trust-us .trustus-wrap .trust-wrap:hover h4 {
  color: #ffffff;
}
.trust-us .trustus-wrap .trust-wrap:hover p{
  color: #ffffff;
}
.about-our-services .about-services-wrappers:hover h4 {
  color: #ffffff;
}
.about-our-services .about-services-wrappers:hover p{
  color: #ffffff;
}



/* START ICON HOVER DATA */
.ourservices-wrap .service-wrap:hover img.off-hover{
  display: none;
}
.ourservices-wrap .service-wrap:hover img.on-hover{
  display: inline-block!important;
  margin: 0 auto;
}
.work-process .work-process-wrap .img-wrap:hover img.off-hover{
  display: none;
}
.work-process .work-process-wrap .img-wrap:hover img.on-hover{
  display: inline-block!important;
}
.trust-us .trustus-wrap .trust-wrap:hover img.off-hover{
  display: none;
}
.trust-us .trustus-wrap .trust-wrap:hover img.on-hover{
  display: inline-block!important;
}

.about-our-services .about-services-wrappers:hover img.off-hover{
  display: none;
}
.about-our-services .about-services-wrappers:hover img.on-hover{
  display: inline-block!important;
}
.contact-form form ul input, .contact-form form ul textarea{
  color: #ffffff;
}

/* END ICON HOVER DATA */


.hamburger-icon {
  display: none;
}
.hamburger-menu-sec {
  display: none;
}

li.mobile-menu-btn,.li.mobile-menu{
  list-style: none;
   font-size: 18px;
}
.hamburger-menu-sec ul {
  padding: 51px 20px;
  text-align: center;
  width: 100%;
}
/* section#Header {
  position: relative;
} */

.hamburger-menu-sec{
  position: realtive;
}


/* slick slider */
.slick-initialized .slick-slide{
  padding: 0 10px;
}


.scroll-to-top {
  left: 40px!important;
  z-index: 999!important;
  right: unset;
}







/* START RESPONSIVE DATA */


@media (min-width: 1300px){
.container{
    max-width: 1600px;
}
.service-page .content-wrapper .content {
  font-size:21px;
}

}
@media (max-width: 1280px){
  .navigation li.nav-item {
    margin-right: 15px;
}
.navigation a.nav-link {
     font-size:15px;
}
.service-page .content-wrapper .title {
  font-size:47px;

}
h1.heading {
  font-size:83px;
}
li.appointment-button button.btn.btn-primary {
  /* margin-top: 5px; */
}
.ourservices-wrap .service-wrap {
  margin-bottom: 37px;
  padding: 0px 15px;
  padding-top: 48px;
  padding-bottom: 70px;
}
.service-page.work-process .work-process-wrap {
  /* padding:0px; */
padding: 40px 0px 60px 0px;
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
max-width: 100%;
flex-wrap: wrap;
}
.service-page.work-process .work-process-wrap .img-wrap {
  width: 33.33%;
  max-width: 100%;

}

.work-process .content-foo p.bottom-cntent {
  margin-top: 20px;
}

.footer p.content {
  width: 331px;
}
.footer-blocks .address .line-items label {
  width: 35%;
  margin-right: 10px;

}
.footer-blocks .address .line-items p {
  width: 65%;
}
.footer .footer-wrapper .footer-blocks:nth-child(1) {
  width:32%;
}
.footer .footer-wrapper .footer-blocks:nth-child(3) {
  width: 25%;
}
.footer .footer-wrapper .footer-blocks:nth-child(4) {
  margin-left: 20px;
}

.work-process .work-process-wrap {
  padding: 60px 0px 50px 0px!important;
}

}

@media (max-width: 1024px){
  section#Header {
    padding: 25px 0;
}

  .container{
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
      text-align: center;

}
.trust-us .trustus-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

}


#Header .logo {
  width: 20%;
  display: inline-block;
}
#Header .navigation {
  width: 85%;
  display: inline-block;
}
#Header .navigation ul.navbar-nav {
  margin: 0;
  width: 100%;
  padding-left: 100px;
}
.navigation li.nav-item {
  display: block;
    margin: 0 auto;
}
.navigation a.nav-link {
  font-size: 13px;
  line-height: 22px;
}
li.appointment-button button.btn {
  padding: 7px 15px;
}



.section.banner {
  padding: 50px 0;
}


.main-banner {
  display: inline-block;
}
.main-banner .content-side {
  max-width: 100%!important;
  text-align: center;

}
.services-icons {
  display: none;
}
.service-page.work-process .work-process-wrap {
  width: 100%;
  display: flex;
  padding: 40px 0px 60px 0px;
  justify-content: space-between;
  gap: 0px;
  flex-wrap: wrap;
}
.service-page.work-process .work-process-wrap .img-wrap {
  width: 33.33%;
  max-width: 100%;
}

.progress-wrap .img-wrap {
  width: 100%;
  max-width: 48%;
  margin-top: 15px;
  margin-left: 13px;
  margin-right: 0px;
  padding: 20px;
  margin-bottom: 15px;


}
.work-process .work-process-wrap {
  padding: 30px 0px 10px 0px;
}
.work-process .work-process-wrap .img-wrap .content-wrapper {
  width: 100px;
  height: 100px;
  padding: 0;
}
.work-process .work-process-wrap .img-wrap .content-wrapper .image img {
  width: 100%;
  padding: 27px;
}
.work-process .work-process-wrap .process-title {
  font-size: 14px;
  padding-top: 15px;
}
.main-banner h4.sub-heading {
  text-align: center;
}
.main-banner h1.heading {
  text-align: center;
}
.main-banner p.description {
  text-align: center;
  margin: 0 auto;
  float: none;
}
.main-banner .content-side .btn {
  margin-top: 25px;
  margin: 0px auto;
  float: none;
  display: inline-block;
  max-width: 240px;
}
ul.slick-dots {
  display: none!IMPORTANT;
}
#Ourservices {
  padding: 30px 0;
}
.our-services p.section-content {
  width: 85%;
}
.ourservices-wrap .service-wrap:nth-child(2) {
  /* margin: 10px 10px; */
  /* margin: 0!important; */
  width: 33.33%;
  margin-top:20px;

}
.ourservices-wrap .service-wrap:nth-child(5){
  /* margin: 10px 10px; */
  /* margin: 0!important; */
  width: 33.33%;
  margin-top: 20px;

}
.ourservices-wrap .service-wrap{
  margin-bottom: 0!important;
  width: 33.33%;
  margin-top: 20px;

}
.ourservices-wrap{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

}
#Progress {
  padding: 18px 0;
}
.portfolio-wrap .img-wrap {
  display: inline-block!important;
  min-height: auto;
}
.portfolio-wrap .content-wrapper{
  width: 100%;
  max-width:100%;
}
.portfolio-wrap .image-wrapper{
  max-width:100%;
}
.portfolio-wrap p.tagline{
  text-align: left;
}
.portfolio-wrap p.technology-name{
  text-align: left;
}
.portfolio-wrap .title{
  text-align: left;
  font-size:34px!important;

}
.portfolio-wrap p.content{
  text-align: left;
    /* margin: 0 auto; */
    float: none;
    display: block;
    width: 100%;
    max-width: 90%;
}
.portfolio-wrap .content-wrapper a.btn{
  text-align: center;
    float: none;
    display: block;
    max-width: 150px;
}
.portfolio-wrap .image-wrapper img {
  padding-right: 0;
  margin: 0 auto;
  float: none;
  display: block;
}
#Bestportfolio {
  padding: 30px 0;
}

#Trustus{
  padding: 40px 0 40px 0;
} 
.trust-us p.section-content {
  width: 80%;
}
.trust-us .trustus-wrap .trust-wrap:nth-child(2) {
  padding: 30px 20px;
    margin: 0 10px;
    max-width: 30%;
}
.trust-us .trustus-wrap .trust-wrap{
  padding: 30px 20px;
  margin: 0 10px;
  max-width: 30%;
}
.trust-us .trustus-wrap .trust-wrap h4 {
  font-size: 20px;
}
.trust-us .trustus-wrap .trust-wrap p {
  font-size: 14px;
}
.customer-says .banner-wrapper .content-section {
  width: 100%;
}
.customer-says .banner-wrapper .customers-wrap {
  width: 100%;
}
.customer-says .banner-wrapper .customers-wrap {
  width: 100%;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
#Customersays {
  padding: 60px 0 40px 0;
}
#Footer {
  padding: 70px 0 0 0;
}
/* .footer .footer-wrapper {
  width: 100%;
  display: inline-block;
} */
/* .footer .footer-wrapper .footer-blocks:nth-child(1) {
  width: 50%;
  display: inline-block;
  float: left;
}
.footer .footer-wrapper .footer-blocks:nth-child(2) {
  width: 50%;
  display: inline-block;
  float: left;
}
.footer .footer-wrapper .footer-blocks:nth-child(3) {
  width: 50%;
  display: inline-block;
  float: left;
}
.footer .footer-wrapper .footer-blocks:nth-child(4) {
  width: 50%;
  display: inline-block;
  float: left;
} */
.footer-blocks {
  min-height: 400px;
}
.footer .footer-copyright {
  margin-top: 0px;
}


.content-side p.description {
  max-width: 67%;
  margin: 0 auto!important;
  padding: 25px 0px;

}


.mg.footr-logocls {
  width: 100%;
  max-width: 70%;
}
.work-process .work-process-wrap .img-wrap .content-wrapper:after {
  width: 110px;
  height: 110px;
  top: -5px;
  left:-5px

}



}

@media (max-width: 768px){
  .main-banner h1.heading {
  font-size: 64px;
}
.main-banner p.description {
  font-size: 18px;
}
.main-banner .content-side .btn {
  max-width: 220px;
  padding: 15px 20px!important;
}
#Turstedclients {
  padding: 40px 0 20px 0;
}
.our-services .section-title {
  font-size: 35px;
}
.our-services p.section-content {
  font-size: 18px;
}
.ourservices-wrap {
  gap: 20px;
}
.ourservices-wrap .service-wrap {
  margin: 15px 0px 20px 0!important;
  width: 48% !important;
  max-width: 100%;
  text-align: center;
}
.trust-us .trustus-wrap {
  width: 100%;
}
.progress-wrap .img-wrap {
  max-width: 47%;
  margin: 10px 10px 10px 10px;
}
.services-wrappers h4 {
  color: #010101;
  font-size: 22px;
}
.work-process .content-foo p.bottom-cntent {
  width: 100%;
  max-width: 90%;
}
.work-process .section-title {
  font-size: 35px;
}
.progress-wrap h2.progress-number{
  font-size: 54px;
}
.progress-wrap p.title {
  color: #000000;
  font-size: 20px;
}
.portfolio-wrap .title{
  font-size:35px;
}
.portfolio-wrap p.content{
  font-size:18px;
  max-width: 80%;

}
.trust-us h2.section-title {
  font-size: 35px;
}
.trust-us p.section-content {
  width: 100%;
  font-size: 18px;
}
.trust-us .trustus-wrap .trust-wrap {
  padding: 30px 20px;
  margin: 0 auto!important;
  max-width: 90%;
  width: 100%;
  margin-bottom: 30px!important;
}
.trust-us .trustus-wrap .trust-wrap:nth-child(2) {
  width: 100%;
  max-width: 90%;
}
.customer-says .banner-wrapper .content-section .title {
  font-size: 35px;
}
.customer-says .banner-wrapper .content-section .content {
  font-size: 18px;
}
.customer-says .banner-wrapper .customers-wrap .content{
  max-width:100%;
}
.footer-blocks {
  min-height: unset;
  width: 100%!important;
  max-width: 100%!important;
  margin-bottom: 45px;
  text-align: left;

}

.block-wrap {
  width: 75%;
}
.main-banner .content-side {
  max-width: 100%!important;
}
.portfolio-wrap .content-wrapper {
  width: 100%!important;
}
.portfolio-wrap .image-wrapper {
  width: 100%!important;
  max-width: 100%!important;
  padding-top: 55px;
}
.portfolio-wrap p.content{
  margin: 0 auto;
  text-align: center;
  width: 100%;

}
.portfolio-wrap .title {
  text-align: left;
  font-size: 43px!important;
  text-align: center;
}
.portfolio-wrap p.tagline {
  text-align: left;
}
.portfolio-wrap p.technology-name{
  text-align: left;
}
.portfolio-wrap .content-wrapper a.btn{
display: inline-block;
margin: 0 auto;


}
.customer-says .banner-wrapper .content-section .title{
  text-align: center;
}
.customer-says .banner-wrapper .content-section .content{
  text-align: center;
}


}



@media (max-width: 620px){

  #Header .logo {
    width: 30%;
}
#Header .navigation {
  width: 60%;
}
.hamburger-icon {
  width: 10%;
}
  .main-banner h1.heading {
    font-size: 47px;
}
.main-banner h4.sub-heading {
  font-size: 13px;
}
.main-banner p.description {
  font-size: 14px;
  max-width: 100%;
}
.main-banner .content-side .btn {
  max-width: 190px;
  padding: 8px 20px;
  font-size: 14px;
}
#Turstedclients h2 {
  font-size: 30px;
}
.our-services .section-title {
  font-size: 30px;
}
.our-services p.section-content {
  font-size: 16px;
  width: 100%;
  max-width: 100%;
}
.ourservices-wrap .service-wrap{
  padding: 30px 20px;
  width: 100%!important;
}
.ourservices-wrap {
  padding-top: 20px;
}
.container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}
.services-wrappers h4 {
  font-size: 35px;
}
.services-wrappers p {
  font-size: 16px;
  line-height: 30px;
}
.services-wrappers .btn {
  font-size: 14px;
  width: 197px;
  padding: 7px 15px;
  width: 100%;
  max-width: 150px;
}
.progress-wrap .img-wrap {
  max-width: 47%;
  margin: 5px 5px 5px 5px;
}
.progress-wrap h2.progress-number {
  font-size: 34px;
}
.progress-wrap p.title {
  font-size: 15px;
}
.work-process .section-title {
  font-size: 30px;
}
.work-process .sub-title {
  font-size: 16px;
}
.work-process .work-process-wrap .img-wrap {
  width: 100%;
  max-width: 50%;
  display: inline-block;
  text-align: center;
}
.work-process .work-process-wrap .img-wrap:nth-child(5) {
  max-width: 100%;
}
.work-process .content-foo p.bottom-cntent {
  max-width: 100%;
  font-size: 16px;
}
.work-process .content-foo a.btn {
  padding: 8px 23px;
  font-size: 14px;
}
.portfolio-wrap p.tagline {
  font-size: 20px;
}
.portfolio-wrap p.technology-name {
  font-size: 14px;
}
.portfolio-wrap .title {
  font-size: 30px;
}
.portfolio-wrap p.content {
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  max-width: 100%;
}
.portfolio-wrap .content-wrapper a.btn {
  text-align: center;
  margin: 20px auto;
  float: none;
  display: block;
  max-width: 150px;
  padding: 8px 20px;
  font-size: 14px;
}
.portfolio-wrap .image-wrapper img {
  width: 100%;
}
.trust-us h2.section-title {
  font-size: 30px;
}
.trust-us p.section-content {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
}
.trust-us .trustus-wrap {
  padding-top: 25px;
}
.trust-us .trustus-wrap .trust-wrap h4 {
  font-size: 18px;
}
#Customersays {
  padding: 40px 0 40px 0;
}
.customer-says .banner-wrapper .content-section .title {
  font-size: 30px;
}
.customer-says .banner-wrapper .content-section .content {
  font-size: 14px;
  line-height: 20px;
}
.customer-says .banner-wrapper .customers-wrap .website-name{
  font-size:22px;
  line-height:50.77px;
}
.customer-says .banner-wrapper .customers-wrap .content{
  font-size: 14px;
  line-height: 20px;
}
.customer-says .banner-wrapper .customers-wrap .customer-name{
  font-size: 14px;
  line-height: 20px;
}
#Footer {
  padding: 40px 0 0 0;
}
.footer p.content{
  width: 100%;
    font-size: 14px;
}
.footer-blocks {
  margin-bottom: 20px;
}
.contact-form .submit-contact-from {
  color: #fff;
  padding: 8px 35px;
  font-size: 14px;
}
.contact-form form ul input, .contact-form form ul textarea {
  font-size: 14px;
}
.footer .footer-copyright p.text-center {
  color: #ffffff;
  font-size: 11px;
}
#Ourservices {
  padding-top: 0;
}



}
@media (max-width: 1200px){
  .container{
    width:100%; 
    max-width: 100%;
  }

  .work-process .work-process-wrap{
    padding: 10px 0px;
  }
  .footer .footer-wrapper .footer-blocks:nth-child(4) {
    width: 21%;
}
.footer .footer-wrapper .footer-blocks:nth-child(2){
  width: 14%;
}
.footer .footer-wrapper .footer-blocks:nth-child(1){
  width:35%
}
.footer .footer-wrapper .footer-blocks:nth-child(3){
  width: 30%;
}

.portfolio-wrap .image-wrapper img{
  width: 100%;
  padding:0px
}
.portfolio-wrap .image-wrapper{
  width:55%
}
.portfolio-wrap .content-wrapper{
  width: 45%;
}

.navbar-brand{
  width: 100%!important;
}

li.appointment-button button.btn.btn-primary{
  font-size: 14px;
}
.navigation li.nav-item {
  margin-right: 10px;
}
ul.navbar-nav.me-auto.mb-2.mb-lg-0 li.nav-item a{
  font-size: 14px;
}
.logo a.navbar-brand.active img {
  width: 100%;
  max-width: 95%;
}
nav.navbar.navbar-expand-lg.bg-light {
  padding: 0px;
}
.progress-wrap p.title{
  font-size:20px ;
}
.work-process .content-foo p.bottom-cntent {
  margin-top: 20px;
}
.footer-wrapper .footer-blocks .block-wrap p.content {
  max-width: 90%;
}
.footer-blocks .address{
  max-width: 90%;
}
}


@media (max-width: 1080px){
.content-side h1.heading {
  font-size: 55px;
}
.main-banner .content-side{
  max-width: 40%;
  padding: 10px 0px;
}


.content h4 {
  font-size: 25px!important;
}
.content p {
  font-size: 21px!important;

}
.cntent-wrapper .wrapper {
  padding: 89px 15px!important;
}

.services-wrappers h4 {
  min-height: 72px;
}
.services-icons {
  width: 60%;
}
.content-side p.description {
  font-size: 16px;
  margin: 0px;
  line-height: 1.6;

}
.portfolio-wrap .content-wrapper{
  width: 45%;
}
.portfolio-wrap .image-wrapper{
  width: 60%;
}
.portfolio-wrap .image-wrapper{
  width: 100%;
  max-width: 55%;
}
.portfolio-wrap .title{
  font-size: 44px;
}
.portfolio-wrap p.content {
 font-size: 16px;
 line-height: 35px;
}
}
@media (max-width: 470px){
  .content-side h1.heading {
    font-size: 35px;
}
.progress-wrap p.title {
  font-size: 14px;
}
.main-banner h4.sub-heading {
  font-size: 10px;
}
.content-side p.description {
  font-size: 12px;
}


}
/* Responsive Services page */

@media (max-width: 1200px){
  .customer-says .banner-wrapper .content-section .title{
    font-size: 40px;
  }
  .customer-says .banner-wrapper .content-section .content{
    width: 80%;
    font-size: 18px;
  }
  .work-process .work-process-wrap .process-title{
    font-size: 18px;
  }
  .work-process .section-title {
    font-size: 45px;
  }
  .work-process .sub-title {
    font-size: 25px;

}
.service-page.image-left .content-wrapper .content{
  width: 95%;
}
.service-page #counter-box .content-wrapper{
  width: 47%;
}
.service-page .image-wrapper{
  max-width: 53%;
}
.service-page.image-left .content-wrapper {
  padding-left: 70px;

}
.service-page .content-wrapper .content{
  max-width: 80%;
  font-size: 20px;
}
.service-page .content-wrapper .title {
  font-size: 40px;
}

}
@media (max-width: 768px){
  .container {
    padding: 0px 20px;
  }


  .service-page.work-process .work-process-wrap .img-wrap{
    max-width: 50%;
    padding: 20px 0px;
  }
  .work-process .work-process-wrap .img-wrap .content-wrapper{
    width: 120px;
    height: 120px;
  }
  .work-process .work-process-wrap .process-title {
    font-size: 19px;
}
.service-page #counter-box{
  display: inline-block!important;
  width: 100%;
}
.service-page #counter-box .content-wrapper{
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 20px;
}
.service-page.image-left .content-wrapper {
  padding-left: 0px!important;
}


.service-page.image-left .content-wrapper .content {
  max-width: 75%!important;
  margin: 0 auto;
}
.service-page .content-wrapper .btn {
  float: none!important;
}
.service-page .image-wrapper{
  float: left;
  width: 100%;
  max-width: 100%;
}
.service-page .content-wrapper .content{
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}
.service-page .image-wrapper .img img{
  width: 80%;
}
.service-page .image-wrapper .img{
  padding-top: 30px;
}
.service-page.image-right .image-wrapper .img .icon-wrapper {
  top: 84px;
  left: 20px;
}
.service-page.image-left .image-wrapper .img .icon-wrapper {
  top: 56px;
    right: -8px;
}
.service-page .content-wrapper .tagline{
  text-align: center;
}
.service-page .content-wrapper .title{
  text-align: center;
}
.service-page .content-wrapper .content {
  padding: 10px 0px;
}
.service-page.image-left .content-wrapper {
padding-left: 0px;
}
.customer-says .banner-wrapper .content-section .content{
  text-align: center;
  width: 100%;
}
.footer .footer-wrapper {
  width: 100%;
  display: inline-block;
}
.footer .footer-wrapper .footer-blocks:nth-child(4) {
  margin-left: 0px;
}
.service-page.work-process .work-process-wrap .img-wrap{
  max-width: 100%;
  width: 100%;

}

}


/* Portfolio Page Responsive */
@media (max-width: 1024px){
  .container{
    text-align: left;
  }
  .service-page .content-wrapper .btn{
    float: left;
  }
  .content h4 {
    font-size: 30px!important;
  }
  .content p {
    font-size: 25px!important;
  }
  .our-portfolio .portfolio-wrap .service-wrap {
    max-width: 50%;
    float: none;
}
.portfolio_popup_wrap .portfolio_popup_img{
  width:20px;
}



}

@media (max-width: 940px){
  
  .footer .footer-wrapper .footer-blocks:nth-child(1){
    max-width: 80%;
    width: 32%;

  }
  .footer .footer-wrapper .footer-blocks:nth-child(2){

  }
  .footer .footer-wrapper .footer-blocks:nth-child(3){
    width: 33%;

}
.footer .footer-wrapper .footer-blocks:nth-child(4){

}
.footer-blocks.block-wrap{
  font-size: 13px;
}
.footer-blocks h2.footer-title {
font-size: 18px;
}
.footer .footer-wrapper .footer-blocks.block-wrap img {
  width: 100%;
  max-width: 70%;
}

}
@media (max-width: 768px){
  .footer .footer-wrapper .footer-blocks.block-wrap img {
    max-width: 55%;
  }
  .footer p.content {
    width: 100%;
    max-width: 90%;
    font-size: 18px;
  }
  .footer-blocks h2.footer-title {
    font-size: 20px;
}
.footer-blocks ul li a.footer-nav-link{
  font-size: 15px;
}
.footer-blocks .address .line-items p{
  font-size: 15px;
}
.footer-blocks .address .line-items label{
 font-size: 15px;
 width: 100px;
}
.our-portfolio .portfolio-wrap .service-wrap{
  max-width: 100%;

}
.work-process .work-process-wrap .img-wrap .content-wrapper {
  width: 150px;
  height: 150px;
}
.portfolio-wrap .content-wrapper a.btn{
  display: block;
}
.portfolio-wrap p.content {
  padding: 25px 0px;

}
.portfolio-wrap p.tagline {
  text-align: center;
}
.portfolio-wrap p.technology-name{
  text-align: center;
}
.work-process .work-process-wrap .img-wrap .content-wrapper:after{
  width: 130px;
  height: 130px;
 
}


}
/* About Us page Responsive */
@media (max-width: 874px){
  .progress-wrap .img-wrap {
    max-width: 45%;
    margin-top: 17px;
    margin-left: 24px;
    margin-right: 0px;
    padding: 10px;
}
  .service-page .image-wrapper .img{
    text-align: center;
  }
  .service-page .image-wrapper .img img{
    width: 100%;
    max-width: 95%;
  }
  .about-our-services .about-services-wrappers .img-wrapper {
    width: 32%;
  }

}
@media (max-width: 768px){
  .about-our-services .about-services-wrappers{
    display: inline-block;
    text-align: center;
  }
  .about-our-services .about-services-wrappers .img-wrapper{
    width: 100%;
    text-align: center;
  }
  .about-our-services .content-wrapper{
    width: 100%;

  }
  .work-process .work-process-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}



.about-our-services .content-wrapper h4{
  padding: 10px 0px;
}
.about-our-services .section-content {
  width: 80%;
}
.work-process .work-process-wrap .img-wrap .content-wrapper {
  width: 120px;
  height: 120px;
}
.awesometeam-wrap .team-member-wrap {
  max-width: 50%;

}
.work-process .work-process-wrap .img-wrap {
  width: 100%;
  max-width: 100%;
  padding: 20px 0px;
}
.about-progress-section h1.title{
  font-size: 62px;
}
.content h4{
  font-size:45px!important;
}
.content p{
  font-size:35px!important;
}


}
@media (max-width: 568px){
  /* .work-process .work-process-wrap .img-wrap .content-wrapper {
    width: 150px;
    height: 150px;
} */
.about-our-services .about-ourservices-wrap .about-service-wrap{
  width: 100%;
}
.awesometeam-wrap .team-member-wrap{
  max-width: 100%;
}
.work-process .work-process-wrap .img-wrap{
  max-width: 100%;
}
.progress-wrap .img-wrap{
  max-width: 100%;
  margin-left: 0px;
}

}
/* contact us page Responsive */
@media (max-width: 1500px){


.btnhover:hover {
    background-color: #ffffff!important;
    border-color: #ffffff!important;
    color:#fc4c69!important;
}

.contact-form-wrapper {
  width: 100%;
  max-width: 95%;
}
.service-page.image-left .content-wrapper .content {
  width: 100%;
  max-width: 100%;
}
.service-page.image-left .content-wrapper {
  padding-left: 65px;
}
.trust-us .trustus-wrap .trust-wrap h4 {
  min-height: 80px;
}
.services-wrappers h4 {
  min-height: 96px;
}

}
@media (max-width: 1330px){
  .contact-form-wrapper{
    top: 50%;

  }
}


@media (max-width: 768px){

.contact-from h1.title {
  font-size: 52px;
}
.contact-from p.content {
  max-width: 80%;
  font-size: 18px;

}
.contact-from form#ContactForm {
  text-align: center;
  padding: 30px 50px 20px 50px;
}
.office-wrapper .address-wrap {
  display: inline-block;
  width: 100%;
}
.address-wrap .address-block {
  width: 100%;
}
.address-wrap .map-block{
  width: 100%;
}
.address-wrap .map-block .map {
  padding: 70px 0px;
}
.contact-form-wrapper{
  padding: 0px;
  height: 670px;
  top: 7%;


}
.contact-from h2.form-heading {
  font-size: 35px;
}
.contact-from h2.form-heading-col {
  font-size: 35px;

}
section#Ouroffice {
  margin-top: 70px;
}


#Contactform{
  height: 1120px;
   position: unset;
}
#Header .logo {
  width:35%;
}
#Header .navigation {
  width: 55%!important;
}
section#Header {
  padding: 6px 0;
}

.popup .wraper-left {
  display: none!important;
}

.popup .text {
  width: 100%;
}
.popup .heading3 {
  font-size: 20px;
}


.popup .submit_popupnew {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.popup .cross {
  width: 3%;
}

}

@media (max-width: 504px){


#Header .logo {
  width: 40%!important;
}
#Header .navigation {
  width: 50%!important;
}



}

@media (max-width: 504px){
  .address-wrap .address-block .address ul li {
    padding-bottom: 40px;
}
.address-wrap .address-block .address{
  padding: 60px 0px 40px 0px;
  height: auto;
}
span.address-content{
  font-size: 18px;
}
.address-wrap .map-block .map {
  padding: 40px 0px;

}
.looking-for .looking-wrapper h2.title{
  font-size: 40px;
}
.looking-for .looking-wrapper p.content {
  font-size: 18px;

}

}

@media (max-width: 480px){
  .contact-from h1.title {
    font-size: 42px;
}
.portfolio_popup_wrap .portfolio_popup_img {
  width: 15px;
}

.content h4{
font-size:27px!important;
}
.content p{
font-size:22px!important;
}

.services-wrappers h4 {
  min-height: 75px;
}
.contact-from p.content {
  max-width: 100%;
  font-size: 16px;
}

.cntent-wrapper .wrapper {
  padding: 0px 15px!important;
}
.main-banner h1.heading {
  font-size: 40px;
}
.work-process .section-title {
  font-size: 40px;
}
.portfolio-wrap .title {
  font-size: 40!important;
}
.work-process .sub-title {
  font-size: 20px;
}
.our-services .section-title {
  font-size: 40px;
}
.contact-from h2.form-heading-col {
  font-size: 25px;
}
.contact-from h2.form-heading {
  font-size: 25px;
}
.contact-form-wrapper {
  top: 47%;
}
.contact-from form#ContactForm .filed-wrap {
  width: 100%;
}
.contact-form-wrapper {
  height: 830px;
}
.contact-form-wrapper {
  top: 6%;
}
.block-wrap {
  width: 100%;
}
.hamburger-menu-sec {
 top:71px!important;
}
.popup .wraper {
  padding: 25px 25px;
}
.popup .heading3 {
  font-size: 18px;
  text-align: center;
}
.popup input.submit {
  padding: 7px 13px;
  max-width: 126px;
  font-size: 18px;
  margin: 0 auto;
}

}


@media (max-width: 380px){
  .contact-from h1.title {
    font-size: 42px;
}

}
/* home page responsive */
@media (max-width: 380px){

.ourservices-wrap{
  width: 100%;
}
.content h4{
 font-size: 22px!important;
}
.content p{
 font-size: 16px!important;
}

.services-wrappers h4 {
  min-height: 60px;
}
}

@media (max-width: 991px){
  .hamburger-icon{
    display: block;
    width: 10%;
    text-align: right;
  }
#Header .navigation {
  width: 70%;
}


section#Header {
  padding: 25px 0;
  position: sticky;
  top: 0;
  z-index: 999999;
  background-color: #ffffff;
  border-bottom: 1px solid #d1d1d1;

}
.hamburger-wrap img{
  width: 30%;
}
.hamburger-menu-sec {
  padding: 0px;
  margin: 0px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  color: #010000;
  z-index: 999;
  min-height: 479px;
  left: 0;
  right: auto;
  top: 95px;
  height: 100vh;
  z-index: 99999;
  display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}



.hamburger-menu-sec ul li {
  list-style: none;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 600;
   border-bottom: 3px solid #fe3e5e;
    width: 100%;
    padding-top: 37px;
    text-align: left;
    border-left: 3px solid #fe3e5e;
    margin-bottom: 33px;
    padding-left: 10px;
    padding-bottom: 5px;
    color: #ffffff;

}
li.mobile-menu-btn button {
  color: #ffffff;
    padding: 19px 31px;
    font-size: 19px;
}


.cancel-btn {
   width: 7%;
   position: absolute;
    top: -53px;
    right: 10px;
}


.cancel-btn img {
  width: 40%;
}

.content h4 {
  font-size: 17px;

}
li.hambtn {
  border-bottom: 0px!important;
  border-left: 0px!important;
  padding-left: 0px!important;
}
a.mobile-menu {
  /* list-style: none; */
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}
.mobile-menu:hover {
color:#fe3e5e;
}
.hamburger-menu-sec ul li:hover{
  border-bottom: 3px solid #ffffff;
  border-left: 3px solid #ffffff;

}
.mobile_menu_wrapper .mobile_menu_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}





}



/* END START RESPONSIVE DATA */





/*Animation Keyframes*/

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

@keyframes blink {
  0% {transform: scale(1, 0.1);}
}



@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 10px); }
  to   { transform: translate(0, -0px); }    
}
@keyframes floating1 {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}



@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes rotate{
  from{ transform: rotate(-360deg); }
  to{ transform: rotate(360deg); }
}
@keyframes rotatex { 100% { transform:rotate(-360deg); } }


@keyframes fade-in {
  to {
      filter: opacity(1);
  }
}

@keyframes open-animate {
  to {
      height: 100vh;
      top: 0;
  }
}
@keyframes line-animate {
  50%, 100% {
      width: 100%;
  }
}


.popup::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: rgb(0, 0, 0);
  top: calc((100% - 2px) / 2);
  left: 0;
  animation: line-animate 0.3s cubic-bezier(0.8, 0.2, 0, 1.2);
}
.popup::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  top: 50%;
  background-color: rgb(0, 0, 0);
  opacity:0.5;
  animation: open-animate 0.3s cubic-bezier(0.8, 0.2, 0, 1.2) forwards;
  animation-delay: 0.3s;
}
.popup > * {
  filter: opacity(0);
  animation: fade-in 0.5s ease-in forwards;
  animation-delay: 0.2s;
}

/* animation for  portfolio pouup */



.portfolio_popup::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 0;
  top: 50%;
  background-color: rgb(0, 0, 0);
  opacity: 0.8;
  animation: open-animate 0.6s cubic-bezier(0.8, 0.2, 0, 1.2) forwards;
}
.portfolio_popup::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  background-color: rgb(255 255 255);
  top: calc((100% - 2px) / 2);
  left: 0;
  animation: line-animate 0.8s cubic-bezier(0.8, 0.2, 0, 1.2);
}
.portfolio_popup > * {
  filter: opacity(0);
  animation: fade-in 0.5s ease-in forwards;
  animation-delay: 0.2s;
}


.new-btnn{
width: 100%;
max-width: 150px;
background-color: #fe3e5e!important;
text-align: center;

}
#textareacontact textarea::placeholder {
  color: #000000;
}
.contact-form .submit-contact-from {
padding: 10px 0px;

}
.msgpopup{
  color:#000000;
}


.submitbtncontact{
color:#ffffff;
}

.submitbtncontact:hover{
  color:#ffffff;
}
.Submitbtn{
  padding: 0px 10px;
}
.alert_msg p {
  padding: 0px 61px;
}

.alert_msg {
  width: 100%;
}

.number .mail-iconimg{
  width:10%
}
.number a{
  width: 90%;
}
.submit-msg.msgpopup {
  max-height: 30px;
}
@keyframes rightIn {
	0% {left: 50px;}
	100% {left: 0px;}
}

@keyframes topIn {
	0% {top: -50px;}
	100% {top: 0px;}
}

@keyframes leftIn {
	0% {left: -50px;}
	100% {left: 0px;}
}

@keyframes bottomIn {
	0% {top: 50px;}
	100% {top: 0px;}
}







/* animation for bannner starts here */
@keyframes wave {
  /* start point */
  0%,
  100% {
    clip-path: polygon(
      0% 46%,
      17% 45%,
      34% 50%,
      56% 61%,
      69% 62%,
      86% 60%,
      100% 51%,
      100% 100%,
      0% 100%
    );
  }
  /* Mid point */
  50% {
    clip-path: polygon(
      0% 59%,
      16% 64%,
      33% 65%,
      52% 61%,
      70% 52%,
      85% 47%,
      100% 48%,
      100% 100%,
      0% 100%
    );
  }
}
/*  */

.text-wrapper {
  position: relative;
}

.text-wrapper h2 {
  z-index: 4;
  color: transparent;
  font-size: 85px;
  white-space: nowrap;
  position: absolute;
  transform: translate(-50%, -90%);
  font-size: 84px;
  text-align: left;
  color: #ffffff;
  font-family: "Georgia - Bold";
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  left: 36%;

}
/* .main-banner h4.sub-heading {
  margin-bottom: 100px;
} */
.text-wrapper h2:nth-child(1) {
  -webkit-text-stroke: 1px solid #ffffff;
}

.text-wrapper h2:nth-child(2) {
  color: #fe3e5e;
  animation: wave 4s ease-in-out infinite;
}



/* animation for banner ends here */


.text{
  color: transparent;
  font-size: 84px;
  display: inline-block;
  border-radius: 3px;
  background-color: #fcfcfc;
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  top:-1px;
  right:0;
  bottom: 0;
  left: -13px;
  &:before{
    content: attr(data-title);
    background: linear-gradient(90deg, #fd6d62 0%, #fc4b69 51%, rgb(255, 255, 255) 100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background-clip: text;
    -webkit-background-clip: text;
    transition: all 1s ease-in-out;
    animation: text-animation 3s infinite;
  }
}



@keyframes text-animation{
0%{
  width: 0;
}
50%{
  width: 100%;
}
100%{
  width: 0;
}
}

